<template>
  <b-card class="mt-n1">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card-header class="pt-0 pl-0">
        <b-card-title class="font-medium-1">
          Ticket
        </b-card-title>
      </b-card-header>
      <b-card-body class="font-medium-1 mb-0 pb-0">
        <div class="text-center mt-n1 pt-0"><b-badge
          pill
          :variant="statusColor"
          class="mb-2"
        >
          <div class="px-3 text-truncate">
            {{ data.results.status.name }}
          </div>
        </b-badge></div>
        <div
          v-if="data.results.resourceDetails"
          class="d-flex align-items-center"
        >
          <b-avatar
            v-if="data.results.resourceDetails.length == 1"
            v-b-tooltip.hover.bottom="data.results.resourceDetails[0].name"
            :src="data.results.resourceDetails[0].img"
            class="pull-up"
          />
          <b-avatar-group
            v-if="data.results.resourceDetails.length > 1"
            size="34px"
          >
            <b-avatar
              v-for="avatar in data.results.resourceDetails"
              :key="avatar.name"
              v-b-tooltip.hover.bottom="avatar.name"
              :src="avatar.img"
              class="pull-up"
            />
          </b-avatar-group>
        </div>
        <div
          v-if="data.results.resourceDetails"
          class="font-small-1 mb-1"
        >
          Assigned Resources
        </div>
        <div>{{ data.results.board.name }}</div>
        <div class="font-small-1 mb-1">
          Board Name
        </div>
        <div v-if="data.results._info.dateEntered">
          <div class="font-small-2 mb-0">
            {{ data.results._info.dateEntered | moment("from") }}
          </div>
          {{ data.results._info.dateEntered | moment("MMMM Do YYYY, h:mm:ss a") }}
        </div>
        <div class="font-small-1 mb-1">
          Date Opened
        </div>
        <div v-if="lastUpdated">

          <div class="font-small-2 mb-0">
            {{ lastUpdated | moment("from") }}
          </div>
          {{ lastUpdated | moment("MMMM Do YYYY, h:mm:ss a") }}
        </div>
        <div
          v-if="lastUpdated"
          class="font-small-1 mb-1"
        >
          Last Updated
        </div>
        <div>{{ data.results.type.name }}</div>
        <div class="font-small-1">
          Ticket Type
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BAvatar, BAvatarGroup, VBTooltip, BBadge, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BAvatar,
    BAvatarGroup,
    BBadge,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    lastUpdated: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
      statusColor: 'primary',
    }
  },
  watch: {
    data(val) {
      if (val.results.status.name === 'Open') {
        this.statusColor = 'success'
      }
      if (val.results.status.name === 'Pending') {
        this.statusColor = 'warning'
      }
      if (val.queryCount === 0) {
        this.show = true
      }
      if (val.queryCount > 0) {
        this.show = false
      }
    },
  },
  mounted() {
  },
  beforeDestroy() {
  },
}
</script>
