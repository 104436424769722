<template>
  <section id="lookup-ticket">
    <b-row class="match-height">
      <b-col
        cols="12"
        class="my-0"
      >
        <ticket-search-box
          :data="ticketNo"
          @changeTicketNo="ticketNo = $event"
          @reset="
            reset();
            errorMessage = '';
          "
        />
        <b-progress
          v-if="searching"
          :value="barValue"
          max="100"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="errorMessage"
      class="match-height"
    >
      <b-col
        cols="12"
        class="my-0"
      >
        {{ errorMessage }}
      </b-col>
    </b-row>
    <b-row v-if="dataTicket.results.summary">
      <b-col cols="12">
        <b-card class="py-0">
          <span class="pl-2 text-uppercase font-weight-bolder font-medium-4">
            <a
              :href="'https://na.myconnectwise.net/v4_6_release/services/system_io/Service/fv_sr100_request.rails?service_recid=' + ticketNo + '&companyName=aventis'"
              target="_blank"
            >SR#{{ ticketNo }}</a>
          </span>
          <span
            v-if="dataTicket.results.summary"
            class="text-uppercase font-medium-4"
          >
            - {{ dataTicket.results.summary }}
          </span>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="ticketNo > 0">
      <b-col
        cols="12"
        md="9"
        class="pr-0"
        order="2"
        order-md="1"
      >
        <ticket-discussion :data="dataTicketNotes" />
      </b-col>
      <b-col
        cols="12"
        md="3"
        class="pl-0 pt-1"
        order="1"
        order-md="2"
      >
        <ticket-contact-info :data="dataContact" />
        <ticket-ticket-info
          :data="dataTicket"
          :last-updated="lastUpdated"
        />
        <ticket-rma-info
          :data="dataTicket"
        />
        <ticket-invoice-info
          v-if="dataInvoice[0].refNumber || searching"
          :data="dataInvoice[0]"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BProgress,
} from 'bootstrap-vue'
import TicketSearchBox from './TicketSearchBox.vue'
import TicketDiscussion from './TicketDiscussion.vue'
import TicketContactInfo from './TicketContactInfo.vue'
import TicketTicketInfo from './TicketTicketInfo.vue'
import TicketInvoiceInfo from './TicketInvoiceInfo.vue'
import TicketRmaInfo from './TicketRmaInfo.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BProgress,
    TicketSearchBox,
    TicketDiscussion,
    TicketContactInfo,
    TicketTicketInfo,
    TicketInvoiceInfo,
    TicketRmaInfo,
  },
  data() {
    return {
      beingDestroyed: false,
      errorMessage: '',
      timer: 0,
      barValue: 0,
      searching: false,
      lastUpdated: '',
      ticketNo: '',
      dataTicket: {
        queryTime: {
          value: '2021-01-01T00:00:00.000+00:00',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: {
          id: 0,
          summary: '',
          board: { name: '' },
          status: { name: '' },
          company: { id: 0, name: '' },
          contact: { id: 0, name: '' },
          contactEmailAddress: '',
          type: { id: 0, name: '' },
          customerUpdatedFlag: false,
          closedFlag: false,
          resources: '',
          _info: {
            dateEntered: '2021-01-01T00:00:00Z',
          },
          customFields: [
            {
              id: 0,
              caption: '',
              type: '',
              entryMethod: '',
              numberOfDecimals: 0,
              value: '',
            },
          ],
          resourceDetails: [{ name: '', img: '' }],
        },
      },
      dataContact: {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 1,
        result: {
          id: 0,
          firstName: '',
          lastName: '',
          fullName: '',
          companyName: '',
          companyId: 0,
          siteName: '',
          siteId: 0,
          inactive: false,
          phoneNumber: '',
          emailAddress: '',
          types: null,
          title: null,
          nickName: null,
          gender: null,
        },
      },
      dataTicketNotes: {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: {
          id: 0,
          ticketid: 0,
          text: '',
          textShort: '',
          name: '',
          type: '',
          dateEntered: '',
          internalFlag: false,
          externalFlag: false,
          emailResource: false,
          emailContact: false,
          emailCc: false,
          toggle: false,
          color: 'success',
        },
      },
      dataInvoice: [
        {
          txnID: '',
          refNumber: '',
          timeCreated: '',
          timeModified: '',
          shipMethodRef_FullName: '',
          other: '',
          subtotal: 0,
          appliedAmount: 0,
          isPaid: true,
          balanceRemaining: 0,
          memo: '',
          salesRepRef_ListID: '',
          customerRef_FullName: '',
          salesRepRef_FullName: '',
          billAddress_Addr1: '',
          billAddress_Addr2: '',
          billAddress_Addr3: '',
          billAddress_Addr4: '',
          billAddress_Addr5: '',
          billAddress_City: '',
          billAddress_State: '',
          billAddress_PostalCode: '',
          billAddress_Country: '',
          shipAddress_Addr1: '',
          shipAddress_Addr2: '',
          shipAddress_Addr3: '',
          shipAddress_Addr4: '',
          shipAddress_Addr5: '',
          shipAddress_City: '',
          shipAddress_State: '',
          shipAddress_PostalCode: '',
          shipAddress_Country: '',
        },
      ],
    }
  },
  watch: {
    async ticketNo(val) {
      if (val) {
        await this.searchTicket()
        this.$router.replace({ path: `/query/ticket/${this.ticketNo}` })
      }
    },
  },
  async created() {
    if (this.$route.params.ticketNo) {
      this.ticketNo = this.$route.params.ticketNo
      await this.searchTicket()
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.barValue === 100) {
        this.barValue = 0
      }
      this.barValue += 5
    }, 600)
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
    this.barValue = null
    this.beingDestroyed = true
    this.reset()
  },
  methods: {
    async searchTicket() {
      this.errorMessage = ''
      this.searching = true
      this.reset()
      await this.$http
        .get(
          `https://asi-api2.azure-api.net/psa/query/ticket?by=ticketNumber&value=${this.ticketNo}&key=836715d7f31643618225af5af3141717`,
        )
        .then(response => {
          this.dataTicket = response.data
        })
        .catch(error => {
          console.log(error)
          this.errorMessage = `Ticket Number Not Found: SR#${this.ticketNo}`
          this.reset()
        })
      if (!this.dataTicket) {
        await this.notFound()
      }
      if (this.dataTicket.queryCount > 0) {
        this.$http
          .get(
            `https://asi-api2.azure-api.net/ticket/contact/view?id=${this.dataTicket.results.contact.id}&key=836715d7f31643618225af5af3141717`,
          )
          .then(response => {
            this.dataContact = response.data
          })
          .catch()
        await this.$http
          .get(
            `https://asi-api2.azure-api.net/ticket/service/notes?id=${this.ticketNo}&key=836715d7f31643618225af5af3141717`,
          )
          .then(response => {
            this.dataTicketNotes = response.data
          })
          .catch(error => {
            console.log(error)
            this.searching = false
            this.errorMessage = `Unable to pull ticket notes for SR#${this.ticketNo}`
          })
        if (
          !this.lastUpdated
          && this.dataTicketNotes.results[0]
          && this.dataTicketNotes.results[0].dateEntered
        ) {
          this.lastUpdated = this.dataTicketNotes.results[0].dateEntered
        }
        if (
          !this.lastUpdated
          && !this.dataTicketNotes.results[0].dateEntered
          // eslint-disable-next-line no-underscore-dangle
          && this.dataTicket.results._info.dateEntered
        ) {
          // eslint-disable-next-line no-underscore-dangle
          this.lastUpdated = this.dataTicket.results._info.dateEntered
        }
        if (this.dataTicket.results.InvoiceNo) {
          await this.$http
            .get(
              `https://asi-api2.azure-api.net/invoice/view/${this.dataTicket.results.InvoiceNo}?key=55d85562ab5f47dba870ae9caf584081`,
            )
            .then(response => {
              this.dataInvoice = response.data
            })
        }
      }
      this.searching = false
    },
    notFound() {},
    reset() {
      if (!this.searching && !this.beingDestroyed) {
        this.ticketNo = ''
        this.$router.replace({ path: '/query/ticket/' })
      }
      this.dataInvoice = [
        {
          txnID: '',
          refNumber: '',
          timeCreated: '',
          timeModified: '',
          shipMethodRef_FullName: '',
          other: '',
          subtotal: 0,
          appliedAmount: 0,
          isPaid: true,
          balanceRemaining: 0,
          memo: '',
          salesRepRef_ListID: '',
          customerRef_FullName: '',
          salesRepRef_FullName: '',
          billAddress_Addr1: '',
          billAddress_Addr2: '',
          billAddress_Addr3: '',
          billAddress_Addr4: '',
          billAddress_Addr5: '',
          billAddress_City: '',
          billAddress_State: '',
          billAddress_PostalCode: '',
          billAddress_Country: '',
          shipAddress_Addr1: '',
          shipAddress_Addr2: '',
          shipAddress_Addr3: '',
          shipAddress_Addr4: '',
          shipAddress_Addr5: '',
          shipAddress_City: '',
          shipAddress_State: '',
          shipAddress_PostalCode: '',
          shipAddress_Country: '',
        },
      ]
      this.lastUpdated = ''
      this.dataTicket = {
        queryTime: {
          value: '2021-01-01T00:00:00.000+00:00',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: {
          id: 0,
          summary: '',
          board: { name: '' },
          status: { name: '' },
          company: { id: 0, name: '' },
          contact: { id: 0, name: '' },
          contactEmailAddress: '',
          type: { id: 0, name: '' },
          customerUpdatedFlag: false,
          closedFlag: false,
          resources: '',
          _info: {
            dateEntered: '2021-01-01T00:00:00Z',
          },
          customFields: [
            {
              id: 0,
              caption: '',
              type: '',
              entryMethod: '',
              numberOfDecimals: 0,
              value: '',
            },
          ],
          resourceDetails: [{ name: '', img: '' }],
        },
      }
      this.dataContact = {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        result: {
          id: 0,
          firstName: '',
          lastName: '',
          fullName: '',
          companyName: '',
          companyId: 0,
          siteName: '',
          siteId: 0,
          inactive: false,
          phoneNumber: '',
          emailAddress: '',
          types: null,
          title: null,
          nickName: null,
          gender: null,
        },
      }
      this.dataTicketNotes = {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: {
          id: 0,
          ticketid: 0,
          text: '',
          textShort: '',
          name: '',
          type: '',
          dateEntered: '',
          internalFlag: false,
          externalFlag: false,
          emailResource: false,
          emailContact: false,
          emailCc: false,
          toggle: false,
          color: 'success',
        },
      }
    },
  },
}
</script>
<style>
img {
  max-width: 500px;
  height: auto;
}
</style>
