<template>
  <b-card>
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card-header class="pt-0 pl-0">
        <b-card-title class="font-medium-1">
          Contact
        </b-card-title>
      </b-card-header>
      <b-card-body class="font-medium-1 mb-0 pb-0">
        <div>{{ data.result.companyName }}</div>
        <div class="font-small-1 mb-1">
          Company Name
        </div>
        <div>{{ data.result.fullName }}</div>
        <div class="font-small-1 mb-1">
          Contact Name
        </div>
        <div>{{ data.result.emailAddress }}</div>
        <div class="font-small-1 mb-1">
          Contact Email
        </div>
        <div v-if="data.result.types">
          {{ data.result.types }}
        </div>
        <div
          v-if="data.result.types"
          class="font-small-1"
        >
          Contact Type
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
    }
  },
  watch: {
    data(val) {
      if (val.queryCount === 0) {
        this.show = true
      }
      if (val.queryCount > 0) {
        this.show = false
      }
    },
  },
  mounted() {
  },
  beforeDestroy() {
  },
}
</script>
