<template>
  <b-card
    v-if="data.results.OutTracking || data.results.InTracking"
    class="mt-n1"
  >
    <b-card-header class="pt-0 pl-0">
      <b-card-title class="font-medium-1">
        RMA Information
      </b-card-title>
    </b-card-header>
    <b-card-body class="font-medium-1 mb-0 pb-0">
      <div
        v-if="data.results.InTracking"
        class="d-flex align-items-center"
      >{{ data.results.InTracking }}</div>
      <div
        v-if="data.results.InTracking"
        class="font-small-1 mb-1"
      >
        Inbound Tracking
      </div>
      <div
        v-if="data.results.OutTracking"
        class="d-flex align-items-center"
      >{{ data.results.OutTracking }}</div>
      <div
        v-if="data.results.OutTracking"
        class="font-small-1 mb-1"
      >
        Outbound Tracking
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      statusColor: 'primary',
    }
  },
  watch: {
  },
  mounted() {
  },
  beforeDestroy() {
  },
}
</script>
