<template>
  <b-card class="mt-n1">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card-header class="pt-0 pl-0">
        <b-card-title class="font-medium-1">
          Invoice Information
        </b-card-title>
      </b-card-header>
      <b-card-body class="font-medium-1 mb-0 pb-0">
        <div
          v-if="data.refNumber"
          class="d-flex align-items-center"
        ><router-link :to="'/query/order/?invoice=' + data.refNumber">
          {{ data.refNumber }}
        </router-link></div>
        <div
          class="font-small-1 mb-1"
        >
          Invoice Number
        </div>
        <div
          v-if="data.subtotal"
          class="d-flex align-items-center"
        >${{ data.subtotal }}</div>
        <div
          v-if="data.subtotal"
          class="font-small-1 mb-1"
        >
          Subtotal
        </div>
        <div
          v-if="data.salesRepRef_FullName"
          class="d-flex align-items-center"
        >{{ data.salesRepRef_FullName }}</div>
        <div
          v-if="data.salesRepRef_FullName"
          class="font-small-1 mb-1"
        >
          Sales Rep
        </div>
        <div
          v-if="data.customerRef_FullName"
          class="align-items-center"
        >{{ data.customerRef_FullName }}</div>
        <div
          v-if="data.billAddress_Addr2 && data.billAddress_Addr2 != data.shipAddress_Addr2"
          class="font-small-2 align-items-center"
        >{{ data.billAddress_Addr2 }}</div>
        <div
          v-if="data.shipAddress_Addr2"
          class="font-small-2 align-items-center"
        >{{ data.shipAddress_Addr2 }}</div>
        <div
          v-if="data.shipAddress_Addr3"
          class="font-small-2 align-items-center"
        >{{ data.shipAddress_Addr3 }}</div>
        <div
          v-if="data.shipAddress_Addr4"
          class="font-small-2 align-items-center"
        >{{ data.shipAddress_Addr4 }}</div>
        <div
          v-if="data.shipAddress_City"
          class="font-small-2 align-items-center"
        >{{ data.shipAddress_City }}, {{ data.shipAddress_State }} {{ data.shipAddress_PostalCode }}</div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
      statusColor: 'primary',
    }
  },
  watch: {
    data(val) {
      if (!val.refNumber) {
        this.show = true
      }
      if (val.refNumber) {
        this.show = false
      }
    },
  },
  mounted() {
  },
  beforeDestroy() {
  },
}
</script>
