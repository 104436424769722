<template>
  <section
    class="mt-n1 mb-0"
  >
    <div class="row mx-2 mb-1">
      <div class="col">
        <hr>
      </div>
      <div class="col-auto font-small-2">
        discussion history
      </div>
      <div class="col">
        <hr>
      </div>
    </div>
    <b-card
      v-if="show"
      class="mt-0 mt-n1 mx-1"
    >
      <b-overlay
        :show="show"
        rounded="sm"
      >
        <b-card-header>
          <b-card-title class="font-medium-1 font-weight-normal">
            <b-avatar
              size="md"
              variant="light-success"
              class="ml-n1 mr-1"
            />
            <span><strong>name placeholder</strong> replied on </span>
          </b-card-title>
          <b-card-text class="font-small-2 mr-25 mb-0">
            <div>
              read more
            </div>
          </b-card-text>
        </b-card-header>
        <p>placeholder text</p>
      </b-overlay>
    </b-card>
    <div v-if="data.results[0]">
      <b-row
        v-for="(note,index) in data.results"
        :key="note.id"
        class="pt-0 mt-0"
      >
        <b-col cols="12">
          <b-card
            class="mt-0 mt-n1 mx-1 mb-2"
            :border-variant="note.color"
          >
            <b-card-header class="mt-n2">
              <b-card-title class="font-medium-1 font-weight-normal">
                <b-avatar
                  badge
                  badge-top
                  :badge-variant="note.color"
                  badge-offset="-3px"
                  size="md"
                  :variant="'light-'+ note.color"
                  class="ml-n1 mr-1"
                  :src="'/img/employees/avatar-s-' + note.identifier +'.jpg'"
                />
                <span v-if="note.name"><strong>{{ note.name }}</strong> replied
                  <span
                    v-if="note.internalFlag"
                    :class="'text-' + note.color"
                  > internally </span>
                  <span v-if="note.type === 'member'"> to the customer </span>
                  <span v-if="note.type === 'customer'"> to the ticket </span>
                  on </span>{{ note.dateEntered | moment("dddd, MMMM Do YYYY, h:mm:ss a") }} ({{ note.dateEntered | moment("from") }})</b-card-title>
              <b-card-text class="font-small-2 mr-25 mb-0">
                <div
                  v-if="!data.results[index].toggle && data.results[index].textShort"
                  @click="data.results[index].toggle = true"
                >
                  read more
                </div>
                <div
                  v-if="data.results[index].toggle && data.results[index].textShort"
                  @click="data.results[index].toggle = false"
                >
                  read less
                </div>
              </b-card-text>
            </b-card-header>
            <p
              v-if="!data.results[index].toggle"
              v-html="data.results[index].textShort"
            />
            <p
              v-if="data.results[index].toggle || !data.results[index].textShort"
              v-html="data.results[index].text"
            />
          </b-card>
        </b-col>
      </b-row>
    </div>
  </section>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BAvatar,
  BCardText,
  BCardTitle,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BAvatar,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
    }
  },
  computed: {
  },
  watch: {
    async data(val) {
      if (val.queryCount === 0) {
        this.show = true
      }
      if (val.queryCount > 0) {
        this.show = false
      }
    },
  },
  methods: {
    replace(text) {
      const result = text.replaceAll('<img', '<img v-b-modal.modal-center ')
      return result
    },
  },
}
</script>
