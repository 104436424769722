<template>
  <b-card>
    <b-form @submit.prevent>
      <b-row class="mb-n2">
        <!-- username -->
        <b-col cols="5">
          <b-form-group>
            <b-input-group
              class="input-group-merge"
              :class="validation ? 'is-valid' : 'is-invalid'"
              @keyup.enter="search()"
            >
              <b-form-input
                id="tf-ticketNo"
                v-model="data"
                placeholder="Ticket Number (SR#)"
                :state="validation"
                length="6"
              />
            </b-input-group>
            <b-form-invalid-feedback :state="validation">
              Not a valid SR Number entered
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="!validation"
            @click="search"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            @click="reset();"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormInvalidFeedback,
  BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BForm,
    BButton,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: String,
      default: () => {},
    },
  },
  computed: {
    validation() {
      return this.data.length > 3 && this.data.length < 7 && /^\d+$/.test(this.data)
    },
  },
  methods: {
    reset() {
      this.data = ''
      this.$emit('reset')
    },
    search() {
      this.$router.push({ path: `/query/ticket/${this.data}` })
      this.$emit('changeTicketNo', this.data)
    },
  },
}
</script>
